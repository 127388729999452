.services {
  padding: 0 1rem 0 1rem;
  display: flex;
  height: 90vh;
  margin-bottom: 8rem;
  margin-top: 9rem;
  margin-bottom: 13rem;
  position: relative;
  left: 0;
}

.services:before,
.services:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 19rem;
  z-index: -1;
}

.services:before {
  left: -4rem;
  right: 2rem;
  background: linear-gradient(
    to right,
    rgba(255, 141, 242, 0.2),
    rgba(0, 0, 0, 0)
  );
}

.services:after {
  right: -4rem;
  background: linear-gradient(
    to left,
    rgba(0, 181, 204, 0.2),
    rgba(0, 0, 0, 0)
  );
}

.awesome {
  display: flex;
  flex-direction: column;
  position: relative;
}

.awesome > :nth-child(1) {
  color: var(--black);
  font-size: 3rem;
  font-weight: bold;
}

.awesome > :nth-child(2) {
  color: var(--orange);
  font-size: 3rem;
  font-weight: bold;
}

.awesome > :nth-child(3) {
  color: var(--gray);
  font-size: 20px;
  margin-top: 1rem;
}

.s-button {
  width: 9rem;
  height: 2rem;
  margin-top: 1rem;
}

.cards {
  position: relative;
}

.cards > * {
  position: absolute;
}

.s-blur1 {
  left: 14rem;
  top: 8rem;
  color: orange;
}

.s-blur2 {
  top: 13rem;
  left: -18rem;
  color: #a020f0;
}
@media screen and (max-width: 480px) {
  .services {
    margin-top: 0;
    flex-direction: column;
    gap: 5rem;
    height: 66rem;
    padding: 0;
  }
  .cards {
    display: flex;
    flex-direction: column;
    gap: 17rem;
  }
  .cards > * {
    position: static;
  }
}
