.FloatingDiv {
  justify-content: space-around;
  display: flex;
  background: white;
  box-shadow: var(--boxshadow);
  border-radius: 17px;
  align-items: center;
  padding: 0px 28px 0px 0px;
  height: 4.5rem;
}

.text {
  margin-top: 0.2rem; /* Adjust margin-top as needed to move the text downwards */
}

.FloatingDiv > img {
  transform: scale(0.4);
}

.crown-logo {
  width: 3rem; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
}
