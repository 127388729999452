.intro {
  display: flex;
  /* height: 77vh; */
  margin-top: 6rem;
}

.i-left {
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  gap: 2rem;
}

.i-right {
  flex: 1;
}

.i-name {
  display: flex;
  flex-direction: column;
  color: var(--black);
  font-size: 3rem;
  font-weight: bold;
}

.i-name span {
  color: var(--orange);
}

.i-name:last-child {
  font-weight: 100;
  font-size: 12px;
  color: var(--gray);
  margin-top: 10rem;
}

.i-button {
  width: 6rem;
  height: 2rem;
}

.i-icons {
  margin-top: 3rem;
  display: flex;
  gap: 1rem; /* Adjust gap as needed */
}

.i-icons > img {
  /* Assuming the resume logo is an image */
  width: 4rem;
  height: 4rem;
  object-fit: contain; /* Ensures the entire image is visible without stretching */
}

.i-icons > a > img {
  width: 4rem;
  height: 4rem;
  object-fit: contain; /* Ensures the entire image is visible without stretching */
}
.i-icons > img,
.i-icons > a > img {
  width: 4rem;
  height: 4rem;
  object-fit: contain; /* Ensures the entire image is visible without stretching */
}

.i-icons > img:last-child,
.i-icons > a > img:last-child {
  width: 4rem; /* Adjust width as needed */
  height: 4rem; /* Adjust height as needed */
  object-fit: contain; /* Ensures the entire image is visible without stretching */
}
.i-icons > img:nth-child(1),
.i-icons > img:nth-child(2) {
  transform: scale(0.5);
}
.i-right {
  flex: 1;
  position: relative;
}
.i-right > * {
  position: absolute;
  z-index: 1;
}
.i-right > :nth-child(1) {
  transform: scale(0.69);
  left: -15%;
  top: -9%;
  clip-path: url("../../img//Vector2.png");
}
.i-right > :nth-child(2) {
  top: -4.6rem;
  transform: scale(0.67);
  left: -3rem;
}
.i-right > :nth-child(3) {
  transform: scale(1.4);
  left: 28%;
}

.i-right > :nth-child(4) {
  transform: scale(0.6);
  top: "-19%";
  left: "24%";
  border-radius: "50%";
  padding: 0;
}
.blur {
  position: absolute;
  width: 22px;
  height: 14px;
  border-radius: 50%;
  background: #c1f5ff;
  z-index: -9;
  top: -18%;
  left: 56%;
  filter: blur(72px);
}
@media screen and (max-width: 480px) {
  .intro {
    flex-direction: column;
    height: 66rem;
    gap: 7rem;
  }
  .i-right {
    transform: scale(0.8);
    left: -4rem;
  }
  .i-right > :nth-child(3) {
    /* Target element within .i-right */
    margin-left: -2rem;
  }
  .i-icons {
    gap: 2rem; /* Adjust the gap value as needed */
  }
}
