.works {
  padding: 0rem 3rem 0rem 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90vh;
  margin-top: 1rem;
}

.w-right {
  position: relative;
  margin-top: 1rem;
}

.w-maincircle {
  position: relative;
  width: 18rem;
  height: 18rem;
  border-radius: 100%;
  box-shadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
  background: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.w-maincircle > * {
  position: absolute;
}

.w-maincircle > :nth-child(1) {
  top: -3rem;
  left: 6rem;
}

.w-maincircle > :nth-child(2) {
  left: -3rem;
  top: 5rem;
}

.w-maincircle > :nth-child(3) {
  left: 6rem;
  top: 5rem;
}
.w-maincircle > :nth-child(4) {
  left: 15rem;
  top: 5rem;
}
.w-maincircle > :nth-child(5) {
  left: 6rem;
  top: 15rem;
}
.w-seccircle {
  width: 6rem;
  height: 6rem;
  position: absolute;
  border-radius: 100%;
  border: 5px solid #ebebeb;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  box-shadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
  background: white;
}
.w-backcircle {
  position: absolute;
  height: 13rem;
  width: 13rem;
  border-radius: 100%;
}
.blue-circle {
  background: lightcyan;
  left: 12rem;
  top: 0rem;

  z-index: -1;
}
.yellow-circle {
  background: rgba(255, 141, 242, 0.2);
  left: 12rem;
  top: 8rem;
  z-index: -1;
}
@media screen and (max-width: 480px) {
  .works {
    flex-direction: column;
    height: 55rem;
    padding: 0;
    gap: 5rem;
  }
  .w-right {
    display: flex;
    align-items: flex-start;
    justify-content: start;
    transform: scale(0.7);
  }
}
