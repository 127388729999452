.portfolio {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 70vh;
}
.portfolio span:nth-of-type(1) {
  color: var(--black);
  font-size: 2rem;
  font-weight: bold;
}
.portfolio span:nth-of-type(2) {
  color: #fca61f;
  font-size: 2.5rem;
  font-weight: bold;
}

/*silder*/

.portfolio.swiper {
  overflow: visible !important;
}
.portfolio-slider {
  margin-top: 3rem;
  width: 100%;
}
.portfolio-slider.swiper-slide {
  width: 20rem;
}
.portfolio img {
  width: 23rem;
  filter: drop-shadow(-12px 15px 13px rgba(0, 0, 0, 0.25));
  border-radius: 23px;
}
@media screen and (max-width: 480px) {
  .portfolio-slider {
    width: 100%; /* Adjust the width to fit the viewport */
    overflow-x: auto; /* Allow horizontal scrolling for small screens */
    white-space: nowrap;
    margin-top: 2rem; /* Add some space at the top for better spacing */
    margin-bottom: 2rem; /* Ensure all images stay in a single row */
  }

  .portfolio-slider .swiper-slide {
    width: 70vw; /* Set a percentage width to make each slider fit within the viewport */
    max-width: 20rem; /* Limit the maximum width of each slider */
    margin: 0 1rem; /* Add some margin between images */
  }

  .portfolio img {
    width: 100%; /* Make sure images take the full width of their container */ /* Ensure images don't exceed their natural size */
    height: auto; /* Allow images to maintain their aspect ratio */
    filter: drop-shadow(-12px 15px 13px rgba(0, 0, 0, 0.25));
    border-radius: 23px;
  }
}
