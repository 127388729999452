.testi {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 70vh;
}
.testi span:nth-of-type(1) {
  color: var(--black);
  font-size: 2rem;
  font-weight: bold;
}
.testi span:nth-of-type(2) {
  color: var(--black);
  font-size: 2.5rem;
  font-weight: bold;
}
.testi span:nth-of-type(3) {
  color: #fca61f;
  font-size: 2.5rem;
  font-weight: bold;
}
/*silder*/

.testi.swiper {
  overflow: visible !important;
}
.testi-slider {
  margin-top: 3rem;
  width: 100%;
}
.testi-slider.swiper-slide {
  width: 20rem;
}
.testi img {
  width: 45rem;
  filter: drop-shadow(-12px 15px 13px rgba(0, 0, 0, 0.25));
  border-radius: 19px;
}
@media screen and (max-width: 480px) {
  .testi {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: auto; /* Adjusted height */
    padding: 2rem 0; /* Added padding for better spacing */
  }

  .testi span:nth-of-type(1),
  .testi span:nth-of-type(2),
  .testi span:nth-of-type(3) {
    color: var(--black);
    font-size: 2rem;
    font-weight: bold;
  }

  .testi span:nth-of-type(3) {
    color: #fca61f;
    font-size: 2.5rem;
  }

  /* Slider */
  .testi-slider {
    width: 100%;
    overflow-x: auto; /* Allow horizontal scrolling for small screens */
    white-space: nowrap; /* Ensure all images stay in a single row */
    margin-top: 3rem; /* Adjusted margin top */
    margin-bottom: 3rem; /* Adjusted margin bottom */
  }

  .testi-slider .swiper-slide {
    width: 70vw; /* Set a percentage width to make each slide fit within the viewport */
    max-width: 20rem; /* Limit the maximum width of each slide */
    margin: 0 1rem; /* Add some margin between slides */
  }

  .testi img {
    width: 100%; /* Make sure images take the full width of their container */
    height: auto; /* Allow images to maintain their aspect ratio */
    filter: drop-shadow(-12px 15px 13px rgba(0, 0, 0, 0.25));
    border-radius: 19px;
  }
}
