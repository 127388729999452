/* Define custom properties */
root {
  --purple: rgb(238, 210, 255);
  --blueCard: #ddf8fe; /* Ensure commas separate RGB values */
}

/* Adjust .contact-form for mobile view */
.contact-form {
  padding: 0 1.5rem; /* Adjust padding for better mobile layout */
  display: flex;
  flex-direction: column; /* Stack items vertically on mobile */
  align-items: center; /* Center items horizontally */
  height: auto; /* Allow height to adjust based on content */
  margin-top: 4rem;
}

.w-left {
  position: relative;
  width: 100%; /* Ensure full width on mobile */
}

.c-right {
  display: flex;
  flex-direction: column; /* Stack items vertically on mobile */
  justify-content: center;
  align-items: center; /* Center items horizontally */
  position: relative;
  width: 100%; /* Ensure full width on mobile */
}

.c-right > form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem; /* Reduce gap between form elements for mobile */
  align-items: center;
  width: 100%; /* Ensure full width on mobile */
}

.c-right .user {
  width: 100%; /* Ensure full width on mobile */
  max-width: 20rem; /* Limit maximum width on larger screens */
  height: 2.5rem; /* Adjust height for better mobile appearance */
  padding: 0.5rem;
  outline: none;
  border: 2px solid var(--orange);
  border-radius: 8px;
  font-size: 16px;
}

textarea {
  width: 100%; /* Ensure full width on mobile */
  max-width: 20rem; /* Limit maximum width on larger screens */
  height: 8rem !important; /* Adjust height for better mobile appearance */
}

/* Remove the blurred effect */
.c-blur1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--purple);
  z-index: -1;
}

/* Media query for screens with a maximum width of 480 pixels */
@media screen and (max-width: 480px) {
  .contact-form {
    padding: 0 1rem; /* Adjust padding for better mobile layout */
    margin-top: 2rem; /* Reduce top margin for better spacing */
  }

  .c-right .user {
    height: 2.2rem; /* Adjust input height for better mobile appearance */
  }

  textarea {
    height: 6rem !important; /* Adjust textarea height for better mobile appearance */
  }
}
