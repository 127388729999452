:root {
  --orangecard: rgba(
    252,
    166,
    31,
    0.45
  ); /* Define the --orangecard variable with the desired color */
}
.Cards {
  width: 10rem;
  height: 13rem;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  width: 10rem;
  text-align: center;

  background: rgba(255, 255, 255, 0.26);
  border: 7px solid var(--orangecard);
  box-shadow: var(--orangecard);
  border-radius: 20px;
}
.Cards > span:nth-of-type(2) {
  color: #788097;
  font-size: 16px;
}
.Cards > img {
  transform: scale(0.6);
  margin-bottom: -2rem;
}
.c-button {
  background: #ffffff;
  box-shadow: 0px 19px 60px rgba(0, 0, 0, 0.08);
  border-radius: 7px;
  border: none;
  padding: 10px;
  font-size: 16px;
  color: #5290fd;
}
