.footer {
  display: flex;
  flex-direction: column; /* Stack items vertically on mobile */
  align-items: center; /* Center horizontally */
  margin: -0.5rem -1.5rem; /* Adjust negative margins for better mobile layout */
  position: relative;
  margin-top: 1rem;
}

.wave-content {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  left: 0;
}

.f-content {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.email {
  margin-bottom: 1rem; /* Adjust margin for better spacing */
  font-size: 20px; /* Decrease font size for better mobile appearance */
}

.f-icons {
  display: flex;
  justify-content: center; /* Center horizontally */
  margin-bottom: 2rem; /* Adjust margin for better spacing */
  gap: 1rem; /* Reduce gap between icons for better mobile layout */
  font-size: 20px; /* Decrease font size for better mobile appearance */
}

/* Media query for screens with a maximum width of 480 pixels */
/* Media query for screens with a maximum width of 480 pixels */
@media screen and (max-width: 480px) {
  .footer {
    margin: -2; /* Reset margins for better mobile layout */
    padding-bottom: 1rem; /* Add padding at the bottom to accommodate the email and icons */
    position: relative; /* Ensure proper positioning */
  }

  .f-content {
    position: relative; /* Ensure proper positioning */
    z-index: 1; /* Ensure content is above wave content */
  }

  .email,
  .f-icons {
    margin-bottom: 1rem; /* Adjust margin for better spacing */
    font-size: 18px; /* Further decrease font size for better mobile appearance */
  }

  .f-icons img {
    /* Target the images within f-icons */
    width: 25px; /* Reduce width of WhatsApp and Telegram icons */
  }
}
